import React, { useRef, useState, useEffect } from 'react';
import {IconButton, Slider, Typography} from "@mui/material";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import DownloadIcon from '@mui/icons-material/Download';

const AudioPlayer = () => {
    const audioRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);

    useEffect(() => {
        const audio = audioRef.current;

        const updateCurrentTime = () => {
            setCurrentTime(audio.currentTime);
        };

        const updateDuration = () => {
            setDuration(audio.duration);
        };

        audio.addEventListener('timeupdate', updateCurrentTime);
        audio.addEventListener('loadedmetadata', updateDuration);

        return () => {
            audio.removeEventListener('timeupdate', updateCurrentTime);
            audio.removeEventListener('loadedmetadata', updateDuration);
        };
    }, []);

    const handlePlayPause = () => {
        if (isPlaying) {
            audioRef.current.pause();
        } else {
            audioRef.current.play();
        }
        setIsPlaying(!isPlaying);
    };

    const handleDownload = () => {
        const link = document.createElement('a');
        link.href = '/audio/canzone-cisalfa.mp3';
        link.download = 'cisalfa.mp3';
        link.click();
    };

    const handleSeek = (e) => {
        const newTime = (e.target.value / 100) * duration;
        audioRef.current.currentTime = newTime;
        setCurrentTime(newTime);
    };

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60);
        return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    };

    return (
        <div style={{
            width: '100%',
            textAlign: 'center',
            padding: '20px',
            margin: 'auto',
            borderRadius: '10px',
            backgroundColor: '#d7703d'
        }}>
            <audio ref={audioRef} src={'/audio/canzone-cisalfa.mp3'}/>
            <Typography>
                Riascolta la canzone di Natale
            </Typography>

                <Slider
                    value={(currentTime / duration) * 100 || 0}
                    onChange={handleSeek}
                    sx={{
                        width: '100%',
                        marginBottom: '10px',
                        color: '#1976d2', // Cambia il colore principale
                        '& .MuiSlider-thumb': {
                            backgroundColor: '#fff', // Colore del "thumb" (cerchio di navigazione)
                            border: '2px solid #1976d2',
                        },
                        '& .MuiSlider-track': {
                            backgroundColor: '#1976d2', // Colore della barra progressiva
                        },
                        '& .MuiSlider-rail': {
                            backgroundColor: '#ddd', // Colore della barra di sfondo
                        },
                }}
                />
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <IconButton onClick={handleDownload} sx={{color: '#fff'}}>
                    <DownloadIcon fontSize="large"/>
                </IconButton>
                <IconButton onClick={handlePlayPause} sx={{marginRight: '10px'}}>
                    {isPlaying ? <PauseIcon fontSize="large"/> : <PlayArrowIcon fontSize="large"/>}
                </IconButton>
                <span>{formatTime(currentTime)} / {formatTime(duration)}</span>
            </div>

        </div>
    );
};

export default AudioPlayer;
