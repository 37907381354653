export const API_URL = "https://api.secondstage.app"
//export const API_URL = "http://localhost:8080"
//export const TOOLPRESS_API = "https://api-tool-press-yn26wrnl4q-ey.a.run.app"
export const TOOLPRESS_API = "https://toolpress-api.2ndstage.app"

export const THEME_CONFIG = {
    mode:"dark",
    gradients: {
        default: ['#f87933', '#f87933'],
        home_question: ['#f87933', '#f87933'],
        applausometro: ['rgba(229,99,74,0)', 'rgba(229,68,138,0)'],
        siNo:['#3a4de4', '#00d8a1'],
        sentiment: ['#3a4de4', '#00d8a1'],
        wordcloud: ['#f87933', '#f87933'],
        qna: ['#f87933', '#f87933'],
    },
    primary: '#f87933',
    secondary: '#263238',
    accent: '#EA4D95',
    text: '#eceff1',
    background:"#09436a",
    poll:{
        default:"#f87933",
        answered:"#ffa06b",
        correct: '#3A722D',
        wrong: '#DB0A16'
    }
}